.root {
  display: grid;
}

.header {
  position: relative;

  & h1 {
    color: var(--color-text-heading);
    font-size: var(--text-3xl);
    font-weight: var(--font-bold);
    letter-spacing: var(--tracking-tightest);
    line-height: var(--leading-3xl);
    margin-bottom: var(--spacing-4);
  }

  & p {
    color: var(--color-text-body);
    margin-bottom: 0;
  }

  & :last-child {
    margin-bottom: var(--spacing-8);
  }
}

.actions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  margin-top: var(--spacing-4);
}

.footer {
  font-weight: var(--font-medium);

  & a {
    margin-left: var(--spacing-2);
    text-decoration: none;
  }
}
