.input {
  border-radius: var(--rounded-md);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
  height: 36px;
  width: 100%;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  margin-top: var(--spacing-4);
}

.wrapper {
  margin-bottom: 0;
}
