.root {
  width: 52%;

  &.middle {
    display: grid;
  }
}

.button {
  font-size: var(--text-sm);
  /* Accounts for the negative space under the button text */
  margin-bottom: var(--spacing-3);
  margin-top: auto;
  padding: 0;

  & :global(.feathr-button-prefix) {
    margin-left: calc(var(--spacing-half) * -1);
    margin-right: var(--spacing-1-half);
  }
}

.top {
  display: flex;
  height: 23vh;
}
