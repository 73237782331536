.forgotPassword {
  color: var(--color-slate-500);
  font-weight: var(--font-normal);
  margin-left: var(--spacing-1);
  text-decoration: none;
}

.input {
  border-radius: var(--rounded-md);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
  height: 36px;
  width: 100%;
}
